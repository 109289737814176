var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alarm-event-list-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [
                    _c("router-link", { attrs: { to: _vm.pagePath } }, [
                      _vm._v(_vm._s(_vm.pageTitle)),
                    ]),
                  ],
                  1
                ),
                _c("a-breadcrumb-item", [
                  _vm._v(_vm._s(_vm.pageSubTitle || "告警管理")),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c(
            "a-card",
            { staticClass: "table-query-card", attrs: { title: "告警查询" } },
            [
              _c("jtl-table-query-component", {
                ref: _vm.JTL.CONSTANT.DEFAULT_TABLE_QUERY_NAME,
                on: { search: _vm.searchClick },
              }),
            ],
            1
          ),
          _c(
            "a-card",
            { attrs: { title: "告警列表" } },
            [
              _c(
                "jtl-button",
                {
                  staticClass: "table-header-button",
                  attrs: { slot: "extra", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleClick(null, true)
                    },
                  },
                  slot: "extra",
                },
                [_vm._v("批量处理")]
              ),
              _c("a-table", {
                attrs: {
                  loading: _vm.listLoading,
                  columns: _vm.tableColumns,
                  "data-source": _vm.listData,
                  pagination: _vm.Pagination,
                  scroll: _vm.tableScroll,
                  "row-key": "id",
                  size: _vm.TableSize,
                  "row-selection": {
                    selectedRowKeys: _vm.selectedRowKeys,
                    onChange: _vm.onSelectChange,
                    onSelect: _vm.onSelect,
                  },
                },
                on: { change: _vm.tableChange },
                scopedSlots: _vm._u([
                  {
                    key: "action",
                    fn: function (text, record, index) {
                      return [
                        record.processStatus === "WCL"
                          ? _c(
                              "span",
                              [
                                _c(
                                  "a",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClick(record, false)
                                      },
                                    },
                                  },
                                  [_vm._v("处理")]
                                ),
                                _c("a-divider", {
                                  attrs: { type: "vertical" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.detailClick(record, index)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { width: "70%", visible: _vm.dialogVisible },
          on: { cancel: _vm.dialogClose },
        },
        [
          _c(
            "div",
            {
              staticStyle: { display: "flex", "align-items": "center" },
              attrs: { slot: "title" },
              slot: "title",
            },
            [_c("div", { staticStyle: { width: "80%" } }, [_vm._v("告警详情")])]
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.eventChange(-1)
                    },
                  },
                },
                [_vm._v("上一个")]
              ),
              _c(
                "a-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.eventChange(1)
                    },
                  },
                },
                [_vm._v("下一个")]
              ),
            ],
            1
          ),
          _c("alarm-event-drawer", {
            attrs: { "event-id": _vm.currentEventId },
            on: {
              close: _vm.onDrawerClose,
              change: function ($event) {
                return _vm.eventChange($event)
              },
              handled: _vm.getList,
            },
          }),
        ],
        2
      ),
      _c("jtl-entity-dialog", {
        ref: "handleFormDialog",
        on: { dialogOK: _vm.getList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }