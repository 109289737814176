import { Component } from 'vue-property-decorator';
import { BaseComponent } from './base-component';
import { SubSystemType } from '@common-src/model/enum';
// import { cancelSource } from '@common-src/service/request';

@Component
export default class SubSystemComponent extends BaseComponent {
    beforeRouteEnter(to, from, next) {
        next(vm => {
            // vm.isFirstLoad = true;
            vm.initPage();
        });
    }
    // beforeRouteLeave(to, from, next) {
    //     console.log('SubSystemComponent beforeRouteLeave');
    //     cancelSource.cancel('cancel request');
    //     next();
    // }
    get SubSystemName(): SubSystemType {
        return this.$route.meta.type as SubSystemType;
    }
}
